const formatError = (error, options, t) => {
  const hasError = Boolean(error)
  if (!hasError) {
    return false
  }

  if (typeof error.message === 'string' && error.message !== '') {
    return error.message
  }

  // Default messages
  if (error.type === 'minLength') {
    return t('DefaultValidationMessages.minLength', {
      defaultValue: 'Minimum length {{ minLength }} was exceeded',
      minLength: options[error.type],
    })
  }

  if (error.type === 'maxLength') {
    return t('DefaultValidationMessages.maxLength', {
      defaultValue: 'Maximum length {{ maxLength }} was exceeded',
      maxLength: options[error.type],
    })
  }

  if (error.type === 'min') {
    return t('DefaultValidationMessages.min', {
      defaultValue: 'Please enter a value of {{ minValue }} or above',
      minValue: options[error.type],
    })
  }

  if (error.type === 'max') {
    return t('DefaultValidationMessages.max', {
      defaultValue: 'Please enter a value below {{ maxValue }}',
      maxValue: options[error.type],
    })
  }

  return hasError
}

export default formatError
